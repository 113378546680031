import React, { useEffect } from 'react';
import { navigate } from "gatsby"

// Hooks & Querys & Context
import { useSiteState } from '../lib/siteContext';

// Components
import Layout from '../components/layout';
import Seo from '../components/global/seo';
import UserMenu from '../components/user/userMenu';

const Account = () => {
    const { 
      setFooterHidden, 
      setHeaderDark, 
      setFooterTheme, 
      user 
    } = useSiteState();

    useEffect(() => {    
        document.documentElement.setAttribute('data-theme', `theme-black`);
        document.documentElement.style.setProperty(`--doc-bg`, '#141414');                  
        setHeaderDark(false)    
        setFooterHidden(false)
        setFooterTheme('theme-none')
        // if (user && user.firstName) {          
          
        // } else {navigate(`/login`)}       
    }, [setFooterHidden, setFooterTheme, setHeaderDark]);    
    
        
    return (
      <Layout>      
      
        <Seo title="Dashboard" metaTitle="Dashboard" />
      
        <div className="block w-full h-24 lg:h-header lg:mb-20"></div>
      
        <UserMenu />
      
        <section className="py-12 lg:py-20 px-4 md:min-h-user">        
          {user && <div className="text-lg">Welcome back <span className="capitalize">{user.firstName}</span></div>}
        </section>
    
      </Layout>
    )
}
export default Account